 
.react-datepicker__input-container input {
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
    border: 1px solid #ececec;
    border-radius: 10px;
    padding-left: 15px;
    height: 42px !important;
    line-height: 42px;
    background-color: #f7f7f7;
    width: 100%;
    position: relative;
 }
  .react-datepicker__input-container input:focus {
    outline: 0;
 }
  .react-datepicker__input-container input ::-ms-input-placeholder {
    color: #adadad;
 }
  .react-datepicker__input-container input :-ms-input-placeholder {
    color: #adadad;
 }
  .react-datepicker__input-container input ::placeholder {
    color: #adadad;
 }
     