.transition__loader {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    visibility: hidden;
    transition: all ease 0.5s, visibility 0s 0.5s;
    &.active {
        opacity: 1;
        user-select: initial;
        pointer-events: initial;
        visibility: visible;
        transition: none;
        .transition__loader__dot {
            animation-name: dot;
        }
    }
    &.hide {
        opacity: 0;
        user-select: none;
        pointer-events: none;
        visibility: hidden;
    }
    &.noTrans {
        display: none;
        transition: none !important;
        will-change: transform;
    }
}

.loader-1 {
	height: 200px;
	width: 200px;
	animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-1 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 200px;
	width: 200px;
	clip: rect(0, 200px, 200px, 100px);
	animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader-1 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 200px;
	width: 200px;
	clip: rect(0, 200px, 200px, 100px);
	border: 3px solid #01B5E2;
	border-radius: 50%;
	animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-1-3 {
	0%   { -webkit-transform: rotate(-140deg); }
	50%  { -webkit-transform: rotate(-160deg); }
	100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}