
.mapInputContainer {
    input {
        font-size: 16px !important;
        font-weight: 500 !important;
        height: auto !important;
        color: #191919 !important;
        border-radius: 10px !important;
        padding: 0 15px !important;
        height: 42px !important;
        width: 100% !important;
        box-shadow: none !important;
        background-color: #F7F7F7;
        border: 1px solid #ececec !important;

        ::-ms-input-placeholder {
            color: rgba(4,30,58,0.3);
        }
        
        :-ms-input-placeholder {
            color: rgba(4,30,58,0.3);
        }
        
        ::placeholder {
            color: rgba(4,30,58,0.3);
            line-height: normal;
        }
    }

    &.error {
        input {
            color: #E74C3C !important;
            border: 1px solid #E74C3C !important;
            box-shadow: inset 0px 0px 0px 1px #E74C3C !important;
        }
    }

    .google-places-autocomplete__suggestions-container {
        background: white !important;
        z-index: 100 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        color: rgba(4,30,58,0.78) !important;
        border: 1px solid #D8D8D8 !important;
        border-radius: 10px !important;
        padding: 0px 0px !important;
        overflow: hidden;
        box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
    }

    .google-places-autocomplete__suggestion  {
        transition: all ease 0.3s;
        margin-bottom: 0 !important;
        line-height: 1.4 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        padding: 12px 15px !important;
        color: rgba(0,21,64,0.78) !important;
        cursor: pointer;
        &:hover {
            background-color: #ececec;
        }
    }
    .gmnoprint {
        display: none;
    }
    .gm-style-cc {
        display: none;
    }
    .gm-fullscreen-control {
        display: none;
    }
}