.transition__container {
    transform: translate3d(0,100vh,0);
    transition: transform cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.7s;
    will-change: transform;
    &.active {
        transform: translate3d(0,0,0);
        transition: transform cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.7s;
        will-change: transform;
    }
    &.hide {
        transform: translate3d(0,-100vh,0);
        transition: transform cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.7s;
        will-change: transform;
    }
    &.noTrans {
        display: none;
        transition: none !important;
        will-change: transform;
    }
}