.spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-animation: rotate-all 1s linear infinite;
    animation: rotate-all 1s linear infinite;
  }
  
  .right-side,
  .left-side {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  
  .left-side {
    left: 0;
  }
  
  .right-side {
    right: 0;
  }
  
  .bar {
    width: 100%;
    height: 100%;
    border-radius: 200px 0 0 200px;
    position: relative;
  }


  .right-side .bar {
    border-radius: 0 200px 200px 0;
    border-left: none;
    transform: rotate(-10deg);
    transform-origin: left center;
    animation: rotate-right 0.75s linear infinite alternate;
  }

  .left-side .bar {
    border-right: none;
    transform: rotate(10deg);
    transform-origin: right center;
    animation: rotate-left 0.75s linear infinite alternate;
  }
  
  @keyframes rotate-left {
    to {
      transform: rotate(30deg);
    }
    from {
      transform: rotate(175deg);
    }
  }
  @keyframes rotate-right {
    from {
      transform: rotate(-175deg);
    }
    to {
      transform: rotate(-30deg);
    }
  }
  @keyframes rotate-all {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  