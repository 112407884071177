.intl-tel-input {
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  color: #191919;
  border: 1px solid #ececec;
  box-shadow: none;
  border-radius: 10px;
  padding: 0 15px;
  padding-left: 15px;
  height: 42px !important;
  line-height: 42px;
  background-color: #F7F7F7;
  width: 100%;
  position: relative;
  input {
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    height: 100%;
  }
  &.error {
    border: 1px solid #E74C3C;
    color: #E74C3C;
  }
}

.selected-flag {
  outline: none;
}

.errorFieldTel {
    .intl-tel-input {
    border: 1px solid #E74C3C !important;
    color: #E74C3C !important;
    }
}

.flag-container {
  padding: 0 !important;
}

.selected-flag {
  padding-left: 15px !important;
  padding-right: 10px !important;
  border-right: 1px solid #ececec;
  width: 57px !important;
}

.selected-flag {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}