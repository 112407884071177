$font: 'Open Sans',sans-serif;

.DayPicker-wrapper {
    padding-bottom: 22px;
    padding-top: 19px;
}

.DayPicker-Caption {
    margin-bottom: 19px;
    div {
        font-family: $font;
        font-weight: 700;
        text-align: center;
    }
}

.DayPicker-Month {
    margin: 0 14px;
}

.DayPicker-NavButton--prev {
    position: absolute;
    left: 24px;
    right: initial;
    top: 19px;
    margin: 0px;
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.5;
    transition: all ease 0.3s;
    &:hover {
        opacity: 1;
        transition: all ease 0.3s;
    }
}

.DayPicker-NavButton--next {
    position: absolute;
    right: 24px;
    left: initial;
    top: 19px;
    margin: 0px;
    width: 12px;
    height: 12px;
    transform: rotate(90deg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.5;
    transition: all ease 0.3s;
    &:hover {
        opacity: 1;
        transition: all ease 0.3s;
    }
}

.DayPicker-Weekday {
    font-family: $font;
    font-size: 12px;
    font-weight: 500;
    color: #B2BBC5;
    padding: 9.5px;
}

.DayPicker-Day {
    font-family: $font;
    font-size: 12px;
    font-weight: 400;
    color: #001E3F;
    padding: 9.5px;
    &:hover {
        color: #01B5E2;
        background: transparent !important;
        transition: all ease 0.3s;
    }
}

.DayPicker-Day--selected,
.DayPicker-Day--today {
    background-color: #01B5E2 !important;
    width: 28px;
    height: 28px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
}

.DayPickerInput {
    width: 100%;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        right: 24px;
        left: initial;
        top: calc(12px + 6px);
        margin: 0px;
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.3;
    }
    input {
        width: 100%;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #041E3A;
        border: 1px solid #ececec !important;
        border-radius: 10px;
        padding: 0px 20px;
        width: 100%;
        height: 42px !important;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &[type=date]::-webkit-clear-button, /* blue cross */
        &[type=date]::-webkit-inner-spin-button, /* up */
        input[type=date]::-webkit-outer-spin-button, /* down */
        &[type=date]::-webkit-calendar-picker-indicator /* datepicker*/ {
            display: none;
        }
        &:focus {
            outline:0;
        }

        ::-ms-input-placeholder {
            color: #041E3A;
        }

        :-ms-input-placeholder {
            color: #041E3A;
        }

        ::placeholder {
            color: #041E3A;
        }
    }

    &.errroDate {
        border-color: #ED2030;
    }
}

.errroDate__container {
    .DayPickerInput {
        &::after {
            content: '';
        }
        input {
            border-color: #ED2030;
        }
    }
}


.DayPickerInput-OverlayWrapper {
    width: 100%;
}

.DayPickerInput-Overlay {
    background: white !important;
    border-radius: 15px;
    border: 1px solid #D8D8D8;
    box-shadow: none;
    background: pink;
    width: 100%;
    display: flex;
    justify-content: center;
}

.form_data_container {
    .react-datepicker-input {
        height: 42px !important;
    }
    font-family: $font;
    &.error {
        .react-datepicker-input {

            input {
                color: #E74C3C !important;
                border: 1px solid #E74C3C !important;
                box-shadow: inset 0px 0px 0px 1px #E74C3C !important;
            }
        }
    }

    .react-datepicker-component .react-datepicker-input {
        border: none;
    }

    .react-datepicker-input .button-wrapper {
        width: 100%;
        height: 42px;
    }

    .react-datepicker-input .button-wrapper .input-button {
        width: 100%;
        height: 42px;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        padding-right: 10px;
        border: 1px solid #ececec;
        border-radius: 10px;
    }

    .react-datepicker-input {
        font-family: $font;
        border: none !important;
        background: transparent !important;
        input {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 10px;
            padding: 0 15px;
            height: 42px !important;
            line-height: 42px;
            background-color: #F7F7F7;
            width: 100%;
            position: relative;
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-family: $font;
            pointer-events: none;
        }
    }

    .react-datepicker .react-datepicker-container .react-datepicker-top {
        background: #01B5E2;
        border-top: 1px solid #01B5E2;
        border-left: 1px solid #01B5E2;
        border-right: 1px solid #01B5E2;
        font-family: $font;
    }
    .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected.current {
        background: #01B5E2 !important;
        font-family: $font;
        color: white;
    }
    .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current {
        background: #fff !important;
        font-family: $font;
    }

    .react-datepicker .react-datepicker-container .react-datepicker-top .display .react-datepicker-button:hover {
        background: #01B5E2 !important;
        color: white;
    }
}

.react-datepicker-component .react-datepicker {
    margin-top: 25px !important;
    font-family: $font;
    margin-bottom: 30px;
}

.react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after {
    border-bottom-color: #01B5E2;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker:not(:last-child) {
    border-color: #D8D8D8;
}

.react-datepicker.floating {
    left: 50% !important;
    transform: translateX(-50%) !important;
}